@use 'sass:math';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

$min-font-size: 0.8;
$max-font-size: 1;
$min-width: math.div(600, 20);
$max-width: math.div(1300, 20);
$slope: math.div($max-font-size - $min-font-size, $max-width - $min-width);
$intercept: $min-font-size - $min-width * $slope;

.home {
  font-size: clamp($min-font-size * 1rem, ($intercept * 1rem) + ($slope * 100vw), $max-font-size * 1rem);

  section {
    position: relative;
    overflow: hidden;
  }

  h2 {
    font-weight: bold;
    font-size: 3em;
    line-height: 1.1;
    color: rgba(0, 0, 0, 0.85);

    &.invert {
      color: rgba(255, 255, 255, 0.85);
    }
  }

  h3 {
    font-weight: bold;
    font-size: 2.5em;
  }

  p {
    font-size: 1em;
    line-height: 1.8;
  }
}

/* MY WORK */

.workHeader {
  background-color: #C7D2DD;
  
  h2 {
    display: inline-block;
  }

  svg {
    position: relative;
    top: 40px;
  }
}

.work {
  img:not(.noBorder) {
    border: 3px solid black;
    max-width: 550px;
  }
}

:global {
  .workExample {
    &:nth-child(2n) {
      @include media-breakpoint-up(md) {
        .preview {
          order: 2;
        }
        .example {
          order: 1;
        }
      }
    }

    &:last-child {
      .preview img {
        border: none;
      }
    }
  }
}

/* DESCRIBE ME */

.describe {
  $bg: #E2EBF4;
  background-color: $bg;

  .quotes {
    position: relative;
    max-width: 1365px;
    margin: 0 auto;

    &:before, &:after {
      position: absolute;
      top: 0;
      height: 100%;
      width: 70px;
      z-index: 999;

      @media screen and (min-width: 1365px) {
        content: '';
      }
    }

    &:before {
      left: 0;
      background: $bg;
      background: linear-gradient(90deg, $bg 0%, transparent 100%);
    }

    &:after {
      right: 0;
      background: $bg;
      background: linear-gradient(90deg, transparent 0%, $bg 100%);
    }
  }

  blockquote {
    position: relative;
    font-size: 16px;
    margin: 0;
    text-align: left;
    width: 375px !important;
    margin: 0 35px;

    p {
      position: relative;
      z-index: 2;
      padding: 50px 43px 120px 43px;
      margin: 0;
      display: flex;
      align-items: center;
    }

    &:first-child {
      p {
        padding-bottom: 130px;
      }
    }

    svg {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  :global {
    .slick-track {
      display: flex;
      align-items: center;
    }

    .slick-slide {
      flex-shrink: 0;
    }

    .slick-arrow {
      position: absolute;
      appearance: none;
      border: none;
      width: 50px;
      height: 50px;
      z-index: 1000;
      padding: 0;
      bottom: -25px;
      margin-top: -50px;
      cursor: pointer;
      transition: 150ms ease;

      &:hover {
        scale: 1.08;
      }

      @media screen and (min-width: 1300px) {
        display: none !important;
      }
    }

    .slick-prev {
      left: 20px;
    }

    .slick-next {
      right: 20px;
    }
  }

  .people {
    width: 687px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  &Bottom {
    position: relative;
    height: 150px;
    background-color: #C7D2DD;
  }

  .calvin {
    width: 147px;
    position: absolute;
    margin-bottom: -34px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* THINGS I CARE ABOUT */

.things {
  display: flex;
  flex-direction: column;
  align-items: center;

  .thing {
    max-width: 450px;
    margin-bottom: 35px;

    img {
      width: 100%;
      margin-bottom: 10px;
    }

    p {
      text-align: center;
    }
  }

  .small {
    max-width: 300px;
    flex: 0.573;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: stretch;
    margin: 0 -14px;

    .thing {
      display: flex;
      flex-direction: column;
      padding: 14px;
      flex: 1;
      margin-bottom: 0;
      max-width: none !important;

      p {
        margin-top: auto;
      }
    }

    .small {
      flex: 0.573;
    }
  }
}

/* GENERIC */

.divider {
  border-bottom: 3px solid black;
}

:global {
  .readingWidth {
    max-width: 800px;
  }

  .contain {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
  }
}
