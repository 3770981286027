@import '../../../../variables';
@import '../../../../mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.infoPane {
  position: relative;
  background-color: var(--info-pane-color);

  h4 {
    line-height: $line-height-large;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-up(lg) {
    width: 420px;
    padding-top: calc(4rem + 280px) !important;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      width: 50vw;
      background-color: var(--info-pane-color);
      height: 100%;
    }
  }
}

.spills {
  @include media-breakpoint-down(lg) {
    overflow: hidden;
    height: 5.4rem;
    position: absolute;
    bottom: 100%;
    width: 100%;
    left: 0;
  }
}

.spillTop {
  position: absolute;
  left: -1rem;
  top: 0;
  fill: var(--info-pane-color);
  height: 5rem;
  transform: rotate(-90deg);

  @include media-breakpoint-up(lg) {
    left: 100%;
    top: 0;
    transform: none;
  }
}

.spillBottom {
  position: absolute;
  right: 0;
  bottom: -0.2rem;
  transform: rotate(270deg);
  fill: var(--info-pane-color);
  height: 5.4rem;

  @include media-breakpoint-up(lg) {
    left: 100%;
    right: auto;
    bottom: 0;
    top: auto;
    transform: none;
  }
}

.iconLink {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: var(--info-p-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 150ms ease;

  svg {
    fill: var(--info-pane-color);
  }

  &:hover,
  &:focus {
    outline: none;
    transform: scale(1.1);
  }

  &:focus {
    box-shadow: 0 0 0px 3px rgb(255 255 255);
  }

  &:hover {
    .tooltip {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }
  }
}
