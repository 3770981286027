.subtitle {
  color: #004BBB;
}

.title, .subtitle, .description, .preview {
  opacity: 0;
}

.in {
  .title, .subtitle, .description, .preview {
    animation: in 400ms ease-out forwards;
  }
  .title {
    animation-delay: 300ms;
  }
  .subtitle {
    animation-delay: 500ms;
  }
  .description {
    animation-delay: 700ms;
  }
}

@keyframes in {
  0%   {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}