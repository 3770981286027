.theme {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 4px;
  border-style: solid;
  border-color: currentColor;
  cursor: pointer;
  transform: scale(1);
  transition: 150ms ease;

  &:hover,
  &:focus {
    outline: none;
    transform: scale(1.1);
  }

  &:focus {
    box-shadow: 0 0 0px 3px rgb(255 255 255);
  }

  &:hover {
    .tooltip {
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }
  }

  &.active:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-width: 0 4px 4px 0;
    border-color: currentColor;
    width: 9px;
    height: 14px;
    transform: translate(-4px, -8px) rotate(45deg);
  }
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  opacity: 0;
  transform: translate3d(-50%, 10px, 0);
  background-color: black;
  color: white;
  padding: 4px 12px;
  font-size: 0.5rem;
  border-radius: 4px;
  margin-bottom: 10px;
  white-space: nowrap;
  pointer-events: none;
}
