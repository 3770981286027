@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

:root {
  // --foliage: #7CDE6B;
  // --bg-foliage: #e2ffdc;
  // --outline: #000000;
  // --fg-outline: #000000;
  // --widget: #c4ae78;
  // --bark: #9c8b45;
  // --bark-shadow: #4f3b26;
  // --shirt: #ff001f;
  // --asdf: #545454;

  --foliage: #a6ecac;
  --leaves: #bffcc2;
  --foliage-alt: #82dc8a;
  --ground: #8ee496;
  --bg-foliage: #e7ffe7;
  // --foliage: #ffb878;
  // --leaves: #ffc786;
  // --foliage-alt: #ffc678;
  // --ground: #ffe278;
  // --bg-foliage: #fffbe7;
  --fg-outline: #000000;
  --outline: #694f3e;
  --widget: #c4ae78;
  --bark: #b6a76a;
  --bark-shadow: #9b7c4c;
  --shirt: #ec1c2b;
  --skin: #f4e4d5;
  --hair: #735353;
}

.hero {
  overflow: hidden;
  width: 100%;
  max-width: 1500px;
  position: relative;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    height: 890px;
    overflow: visible;
  }

  h1 {
    opacity: 0;
    position: relative;
    font-size: 3.25em;
    line-height: 1;
    font-weight: bold;
    animation: in 1200ms ease 300ms forwards;

    // @include media-breakpoint-up(md) {
    //   -webkit-text-stroke: 20px white;

    //   &:after {
    //     content: 'Calvin Goodman';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     z-index: 2;
    //     -webkit-text-stroke: 0;
    //   }
    // }
  }
}

.heroContent {
  position: relative;
  margin-top: 400px;
  padding: 50px 20px;
  background-color: var(--ground);
  z-index: 2;

  @media screen and (min-width: 480px) {
    margin-top: 600px;
    padding: 50px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
    padding: unset;
    padding-top: 330px;
    background-color: transparent;
  }

  .slogan {
    font-size: 1.4em;
    font-weight: 400;
    opacity: 0;
    animation: in 1200ms ease 1000ms forwards;
  }
}

.intro {
  max-width: none;
  opacity: 0;
  animation: in 1200ms ease 1400ms forwards;

  @include media-breakpoint-up(md) {
    max-width: 46%;
  }
}

.bigTree {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  height: 400px;
  margin-left: -500px;

  @media screen and (min-width: 480px) {
    height: 600px;
    margin-left: -780px;
  }

  @include media-breakpoint-up(md) {
    height: 890px;
    margin-left: -932px;
  }

  svg {
    height: 100%;
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 100%;
    top: 0;
    background-color: var(--bg-foliage);
  }
}

.contact {
  display: flex;
  background-color: var(--ground);
  padding: 0 20px 30px 20px;

  @media screen and (min-width: 480px) {
    padding: 0 50px 30px 50px;
  }

  @include media-breakpoint-up(md) {
    background-color: transparent;
    position: absolute;
    align-items: center;
    bottom: 30px;
    z-index: 10;
    padding: 0;
  }

  .button {
    position: relative;
    display: inline-block;
    font-weight: 300;
    padding: 0 20px;
    font-size: 16px;
    appearance: none;
    cursor: pointer;
    border: 3px solid black;
    // background-color: white;
    color: black;
    height: 48px;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: white;
      z-index: -1;
      transition: 150ms ease;
    }

    &:hover,
    &:focus {
      &:before {
        width: 100%;
      }
    }
  }
}

.iconLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 3px solid transparent;
  border-radius: 50%;
  color: black !important;
  transition: 150ms ease;

  &:hover,
  &:focus {
    background-color: white;
    border-color: black;
  }
}

:global {
  .outline {
    fill: var(--outline);
  }
  .fgOutline {
    fill: var(--fg-outline);
  }
  .bark {
    fill: var(--bark);
  }
  .barkShadow {
    fill: var(--bark-shadow);
  }
  .widget {
    fill: var(--widget);
  }
  .bgFoliage {
    fill: var(--bg-foliage);
  }
  .shirt {
    fill: var(--shirt);
  }
  .skin {
    fill: var(--skin);
  }
  .hair {
    fill: var(--hair);
  }
  .ground {
    fill: var(--ground);
  }
  .foliage {
    fill: var(--foliage);
  }
  .desert-1 {
    fill: var(--desert-1);
  }
  .desert-2 {
    fill: var(--desert-2);
  }
  .desert-3 {
    fill: var(--desert-3);
  }
  .desert-4 {
    fill: var(--desert-4);
  }
  .desert-5 {
    fill: var(--desert-5);
  }
  .leaf {
    fill: var(--leaves);
    :local {
      animation-name: wind;
    }
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @for $i from 1 through 34 {
      $start: ($i - 1) * 200ms;

      &.leaf-#{$i} {
        animation-delay: $start;
      }
    }
  }

  .left-leaves:local {
    fill: var(--foliage);
    animation: subtle-wind 5s 3s ease infinite alternate;
  }

  .right-leaves:local {
    fill: var(--foliage-alt);
    animation: subtle-wind 5s ease infinite alternate;
  }
}

@keyframes in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wind {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-15px, 8px, 0);
  }
}

@keyframes subtle-wind {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-10px, 6px, 0);
  }
}
