@import '../../../../variables';
@import '../../../../mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

$diamond-size: 1.3rem;
$top: calc(($h1-font-size - $diamond-size) / 2);

.timelinePane {
  position: relative;
  z-index: 2;

  h1 {
    color: var(--h1-color);
    line-height: 1;
    min-height: 2.4rem;
    display: flex;
    align-items: center;
  }

  h2 {
    color: var(--role-color);
  }

  :global {
    .outline {
      fill: var(--illustration-outline-color);
    }

    .fill {
      fill: var(--illustration-bg-color);
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 4.5rem !important;
  }
}

.illustration {
  max-height: 280px;

  @include media-breakpoint-up(sm) {
    width: auto;
    height: 280px;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 4rem;
    right: 100%;
  }
}

.intro {
  position: relative;
  color: var(--timeline-p-color);

  &:after {
    content: '';
    width: $diamond-size;
    height: $diamond-size;
    border: 2px solid var(--timeline-color);
    background-color: var(--timeline-bg-color);
    position: absolute;
    top: $top;
    left: calc($diamond-size / -2);
    animation-name: diamond-in;
    animation-fill-mode: both;
    animation-duration: 300ms;
    animation-timing-function: ease;
  }

  &:not(:last-child):before {
    content: '';
    width: 2px;
    background-color: var(--timeline-color);
    position: absolute;
    left: -1px;
    top: $top;
    animation: line-in 500ms both linear 300ms;
  }
}

.name {
  @include slide-in;
}

.tagline {
  @include slide-in;
  animation-delay: 400ms;
}

.introBody {
  @include slide-in;
  animation-delay: 600ms;
}

@keyframes diamond-in {
  from {
    transform: rotate(-215deg) scale(0);
  }
  to {
    transform: rotate(45deg) scale(1);
  }
}

@keyframes line-in {
  from {
    bottom: 100%;
  }
  to {
    bottom: -$top;
  }
}
