@import '../../../../variables';
@import '../../../../mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

$top: calc($h3-font-size * $headings-line-height / 2 - 0.5rem);

.job {
  position: relative;
  break-inside: avoid;

  &:after {
    content: '';
    width: 1rem;
    height: 1rem;
    border: 2px solid var(--timeline-color);
    background-color: var(--timeline-bg-color);
    border-radius: 50%;
    position: absolute;
    top: $top;
    left: -0.5rem;
    animation-name: dot-in;
    animation-fill-mode: both;
    animation-duration: 300ms;
  }

  &:not(:last-child):before {
    content: '';
    width: 2px;
    background-color: var(--timeline-color);
    position: absolute;
    left: -1px;
    top: $top;
    animation-name: line-in;
    animation-fill-mode: both;
    animation-duration: 500ms;
    animation-timing-function: linear;
  }

  @for $i from 0 through 3 {
    $start: $i * 500ms + 800ms;

    &:nth-child(#{$i + 1}) {
      .header {
        animation-delay: $start + 100ms;
      }
      .tenure {
        animation-delay: $start + 150ms;
      }
      .description {
        animation-delay: $start + 200ms;
      }

      &:after {
        animation-delay: $start;
      }

      &:before {
        animation-delay: $start;
      }
    }
  }
}

@keyframes dot-in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes line-in {
  from {
    bottom: 100%;
  }
  to {
    bottom: -$top;
  }
}

.header {
  @include slide-in;
}

.title {
  font-size: $h3-font-size;
  color: var(--job-title-color);
}

.company {
  font-size: $h3-font-size;
  color: var(--company-color);
}

.tenure {
  color: var(--tenure-color);
  font-size: 0.7rem;
  @include slide-in;
}

.description {
  color: var(--timeline-p-color);
  @include slide-in;
}
