@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.body {
  margin: 0 auto;

  @include media-breakpoint-up(xl) {
    max-width: 1140px;
  }
  @include media-breakpoint-up(xxl) {
    max-width: 1320px;
  }
}
