.me {
  width: 100%;
  max-width: 550px;
}

.about {
  .me, h2, p {
    opacity: 0;
  }
}

.in {
  .me, h2, p {
    animation: in 400ms ease-out forwards;
  }
  h2 {
    animation-delay: 300ms;
  }

  @for $i from 0 through 3 {
    $start: $i * 200ms + 600ms;

    p:nth-child(#{$i + 2}) {
      animation-delay: $start;
    }
  }
}

@keyframes in {
  0%   {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}