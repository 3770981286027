@import 'variables';
@import 'mixins';

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

html {
  font-size: 20px;
}

body {
  font-weight: 300;
  background-color: var(--timeline-bg-color);
}

p {
  line-height: $line-height-large;
}

.divider {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: var(--divider-color);
  display: inline-block;
}

.social-icon {
  width: 30px !important;
  height: 30px !important;

  .social-svg-mask {
    fill: var(--info-title-color) !important;
  }
}
